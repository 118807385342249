/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react"
import style from "../../styles/pages/addSimplePosition.module.scss"
import Barcode from "../images/barcode.svg?react"
import ArrowDown from "../images/arrowDown.svg?react"
import Info from "../images/info.svg?react";
import Check from "../images/check.svg?react"
import Spinner from "../images/spinner.svg?react"
import OutsideClicker from "../helpers/OutsideClicker";
import BarcodeScanner from '../../../Modal/BarcodeScanner';
import Wallet from "../../svg/wallet-3-line.svg?react";
import BankCard from "../../svg/bank-card-line.svg?react";
import Transfer from "../../svg/transfer.svg?react";
import { useTranslation } from "react-i18next";
import { OPERATION_TYPES } from "../../../../constants/app";
import { Capacitor } from "@capacitor/core";
import { openScanBarcode } from "../../../../Utils/scan-barcode";

const FindArticle = ({ articles, name, handleClickArticle }) => {
  const filteredArticles = articles.filter(article => 
    article.Name.toLowerCase().includes(name.toLowerCase())
  )

  if (filteredArticles.length === 0) return null;
  return (
    <div className={style.dropdownList} style={{maxHeight: '403px', padding: "10px 0"}}>
      {
        filteredArticles.map(article => (
          <div className={style.dropdownItem} 
               key={article.Id}
               style={{padding: '6px 16px', borderRadius: '0'}}
               onClick={() => handleClickArticle(article.Id)}>
            {article.Name}
          </div>
        ))
      }
    </div>
  )
}

const AddSimplePosition = ({context, status, history}) => {
  const { t } = useTranslation()

  let kkm = (JSON.parse(localStorage.getItem('KKM')))

  useEffect(() => {
    if (context.CheckImg) {
      history.push(`/www/kkms/${kkm.Id}/check/success`)
    }
  }, [context])

  const [showArticles, setShowArticles] = useState(false)
  const [showSections, setShowSections] = useState(false)
  const [emptyForm, setEmptyForm] = useState(true)
  const [nameError, setNameError] = useState(false)
  const [priceError, setPriceError] = useState(false)
  const [excise, setExcise] = useState(false)
  const [showScanner, setShowScanner] = useState(false)
  const [paying, setPaying] = useState(false)
  const [active, setActive] = useState(1)

  const openNativeBarcodeScan = async () => {
    try {
      if (Capacitor.isNativePlatform()) {
        const result = await openScanBarcode();

        context.Articles.map(
          art =>
            art.Qr === result && context.setArticle(art, true, status)
        )
      } else {
        setShowScanner(true);
      }
    } catch (e) {}
  };


  useEffect(() => {
    if (context.Position.Name || context.Position.Price || context.Position.MarkupPercent || context.Position.DiscountPercent) {
      setEmptyForm(false)
    } else {
      setEmptyForm(true)
    }
  }, [context.Position])


  const onlyNumber = (e) => {
    let keyCode = (e.keyCode ? e.keyCode : e.which);
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 8) {
      e.preventDefault();
    }
  }

  const setQr = context => code => {
    context.Articles.map(
      art =>
        art.Qr === code && context.setArticle(art, true, status)
    )
  }

  const handleClickArticle = id => {
    context.Articles.map(
      art =>
        art.Id === id && (
          context.setArticle(art, true, status)
        )
    );
    setShowArticles(false)
    setNameError(false)
    setPriceError(false)
  }

  const handleChangeArticle = (name) => {
    context.nameInputHandler({ value: name }, status)
    setNameError(false)
  };

  const handlePriceChange = (price) => {
    const regex = /^\d*\.?\d{0,2}$/;
    const numericPrice = parseFloat(price);

    if (price === '') {
      context.priceInputHandler({ value: '', name: 'Price' }, status);
      setPriceError(false);
      return;
    }
    
    if (!isNaN(numericPrice) && regex.test(price)) {
      context.priceInputHandler({ value: price, name: 'Price' }, status);
      setPriceError(false);
    }
  };

  const handleInputExciseCode = (value, type) => {
    context.exciseCodeInputHandler({value, name: type}, status);
  }

  const handleInputChange = (e, func, val, objName) => {
    if (e.target.value === Number(e.target.value) && Number(e.target.value) >= 0) {
        func({ value: e.target.value, name: val, objName: objName });
    }
  }

  const setPayType = (type) => {
    setActive(type)
    context.setSum(type)
  }

  const pay = () => {
    if (!context.Position.Name) {
			context.setNameError(true)
		} else {
			context.setNameError(false)
		}
		if (!context.Position.Price) {
			context.setPriceError(true)
		} else {
			context.setPriceError(false)
		}
    if (context.Position.Name && context.Position.Price && context.Total <= (context.Income.Cash + context.Income.NonCash + context.Income.Mobile)) {
      setPaying(true)
      if (status === OPERATION_TYPES.SALE.KEY) {
        context.makeSale('sales').finally(() => setPaying(false))
      } else if (status === OPERATION_TYPES.PURCHASE.KEY) {
        context.makePurchase('purchases').finally(() => setPaying(false))
      } else if (status === OPERATION_TYPES.REFUND.KEY) {
        context.makeRefund('refunds').finally(() => setPaying(false))
      } else if (status === OPERATION_TYPES.PURCHASE_REFUND.KEY) {
        context.makeRefund('purchase_refunds').finally(() => setPaying(false))
      }
    }
  }

  const clearForm = () => {
    context.resetPosition()
  }


  return (
    <div className={style.addPosition}>
      <div className={style.header}>
        <p className={style.title}>{ t('receipt.tota_amount') }</p>
        {
          emptyForm ? (
            <p className={style.clear}>{ t('receipt.clear_form') }</p>
          ) : (
            <p className={style.clearActive} onClick={() => clearForm()}>{ t('receipt.clear_form') }</p>
          )
        }
      </div>

      <div className={style.body}>

        <div className={style.inputWrapper}>
          <label htmlFor="">{ t('receipt.item_name') }</label>
          <OutsideClicker setShow={() => setShowArticles()}>
            <div className={style.dropdownWrapper}>
              <div className={style.barcodeWrapper}>
                <input
                  type="text"
                  placeholder={ t('receipt.enter_name') }
                  className={(nameError || context.NameError) && style.errorInput}
                  value={context.Position.Name}
                  onChange={(e) => handleChangeArticle(e.target.value)}
                  onFocus={() => setShowArticles(true)}
                  maxLength={250}
                />
                <div className={style.barcode} onClick={openNativeBarcodeScan}>
                  <Barcode />
                </div>
              </div>
              {
                showArticles && <FindArticle articles={context.Articles} name={context.Position.Name} handleClickArticle={(id) => handleClickArticle(id)} />
              }
            </div>
          </OutsideClicker>
          {(nameError || context.NameError) && <p className={style.errorText}>{ t('other.enter_name') }</p>}
        </div>

        <div className={style.inputWrapper}>
          <label htmlFor="">{ t('receipt.price') }</label>
          <input
            type="text"
            placeholder="0.00"
            className={(priceError || context.PriceError) && style.errorInput}
            value={context.Position.Price === 0 ? '' : context.Position.Price}
            onKeyUp={(e) => onlyNumber(e)}
            onChange={(e => handlePriceChange(e.target.value))}
          />
          {(priceError || context.PriceError) && <p className={style.errorText}>{ t('other.enter_amount') }</p>}
        </div>

        <div className={style.inputWrapper}>
          <div className={style.checkboxWrapper} onClick={() => setExcise(!excise)}>
            {
              excise ? (
                <div className={style.checkboxActive}>
                  <Check />
                </div>
              ) : (
                <div className={style.checkbox}></div>
              )
            }
            <p>{ t('receipt.excise_goods') }</p>
          </div>
          {
            excise && (
              <input
                type="text"
                placeholder={ t('other.excise_code') }
                className={style.excise}
                value={context.Position.ProductCode}
                onChange={e => handleInputExciseCode(e.target.value, 'ProductCode')}
              />
            )
          }
        </div>

        <div className={style.inputWrapper}>
          <label htmlFor="">{ t('receipt.section') }</label>
          <OutsideClicker setShow={setShowSections}>
            <div className={style.dropdownWrapper} onClick={() => setShowSections(!showSections)}>
              <div className={style.dropdown}>
                <p>{context.Position.Section.Name}</p>
                <ArrowDown />
              </div>
              {
                showSections && (
                  <div className={style.dropdownList}>
                     {
                        context.Sections.map(section => (
                          <div className={context.Position.Section.Id === section.Id ? style.activeDropdownItem : style.dropdownItem} key={section.Id} onClick={() => context.sectionChangeHandler(section.Id, status)}>
                            {section.Name}
                          </div>
                        ))
                      }
                  </div>
                )
              }
            </div>
          </OutsideClicker>
        </div>

        <div className={style.inputWrapper}>
          <div className={style.iinWrapper}>
            <label htmlFor="">{ t('receipt.customer_tax') }</label>
            <Info />
            <div className={style.popup}>
              { t('other.iin_bin_optional') }
            </div>
          </div>
          <input
            type="text"
            inputMode="numeric"
            placeholder="000 000 000 000"
            maxLength="12"
            value={context.CustomerIin}
            onChange={ e => handleInputChange(e, context.iinHandler) }
          />
          {
            context.CustomerIinError && <p className={style.errorText}>{ t('other.enter_valid_bin_iin') }</p>
          }
        </div>

        <div className={style.payType}>
          <button disabled={active === 1} className={active === 1 ? style.cashActive : style.cash}
                  onClick={() => setPayType(1)}>
            <Wallet/>
            <span>{t('receipt.cash_alt_2')}</span>
          </button>
          <button disabled={active === 2} className={active === 2 ? style.nonCashActive : style.nonCash}
                  onClick={() => setPayType(2)}>
            <BankCard/>
            <span>{t('receipt.card_alt')}</span>
          </button>
          <button disabled={active === 3} className={active === 3 ? style.mobileActive : style.mobile}
                  onClick={() => setPayType(3)}>
            <Transfer/>
            <span>{t('receipt.transfer_alt')}</span>
          </button>
        </div>

        <div className={style.actions}>
          <button
            className={style.pay}
            disabled={!context.NewPreCheck[0].id || paying}
            onClick={() => pay()}
          >
            {
              paying ? (
                <Spinner />
              ) : (
                t('receipt.issue_receipt')
              )
            }
          </button>
        </div>

      </div>


      <BarcodeScanner
        setCode={setQr(context)}
        setShowScanner={setShowScanner}
        showScanner={showScanner}
      />
    </div>
  )
}

export default AddSimplePosition;
