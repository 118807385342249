import React, { Component } from 'react';
import axios from 'axios';
import {BASE_URL, getUser, getError } from '../../../Store/snippets';
import CompaniesList from './CompanyList';
import PreloaderFullScreen from '../../Preloader/Preloader';
import { PULT_API_DEV_URL, PULT_API_PROD_URL, FISCAL_DEV_HOST, KKM_STATUS_DEFAULT } from '../../../constants/app';

let Token, headers;
const hostName = window.location.host;
let PULT_URL = PULT_API_DEV_URL;

if (import.meta.env.MODE === 'production') {
	if (hostName === FISCAL_DEV_HOST) {
		PULT_URL = PULT_API_DEV_URL;
	} else {
		PULT_URL = PULT_API_PROD_URL;
	}
}

export const DEFAULT_PAGINATION = {
  currentPage: 1,
	pageSize: 7,
	total: 0,
	totalPages: 0, // total / pageSize
  kkms: []
};

let isElectron = require("is-electron");

class Companies extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			companies: [],
			shiftOpen: false,
			usersCompanyId: null,
			choosenCompanyId: null,
			companyName: '',
			error: false,
			errorData: '',
			kkmLoading: false,
			mounted: false,
			currentExpiredDates: {},
			pending: false,
			copied: false,
			znmForCopy: 0,
			finishing: false,
			balances: {},

			// Пагинация
      ...DEFAULT_PAGINATION,
			isLoading: false,

			// Поиск
			allKkms: [],
			searchQuery: '',
			isSearchMode: false,
			status: KKM_STATUS_DEFAULT.id
		};
		this.controller = new AbortController()
	}

	selectCompanyById = (companies, id) => {
		localStorage.setItem('COMPANY', JSON.stringify(companies[id]));
	}

	fetchKkms = async (pageNumber) => {
		const { choosenCompanyId, pageSize, searchQuery, status } = this.state;

		this.setState({ kkmLoading: true });

		try {
			const body = {
				companies: [choosenCompanyId],
				message: this.state.searchQuery || "",
				currentPage: searchQuery.trim() !== '' ? 1 : pageNumber,
				pageSize: searchQuery.trim() !== '' ? 1000 : pageSize,
			}
			if (status) {
				body.status=status
			}

			const response = await axios.post(`${BASE_URL}/kkms`, body, { headers, signal: this.controller.signal });

			const { total, currentPage, pageSize: returnedPageSize, kkms } = response.data.Data;
			const kkmsWithLabels = this.getKkmsWithLabels(kkms);

			if (searchQuery.trim() !== '') {
				this.setState({
					allKkms: kkmsWithLabels,
					isSearchMode: true,
					currentPage: 1,
					pageSize: DEFAULT_PAGINATION.pageSize,
					total: kkmsWithLabels.length,
					totalPages: Math.ceil(kkmsWithLabels.length / DEFAULT_PAGINATION.pageSize),
					kkmLoading: false,
					pending: true,
				}, () => {
					if (!this.state.currentExpiredDates || Object.keys(this.state.currentExpiredDates).length === 0) {
						this.fetchExpiredDates(choosenCompanyId);
					} else {
						const updatedKkms = this.getKkmsWithDates(this.getCurrentPageKkms());
						this.setState({ kkms: updatedKkms, pending: false });
					}
				});

			} else {
				const newTotal = total;
				const newCurrentPage = currentPage;
				const newPageSize = returnedPageSize;
				const newTotalPages = Math.ceil(newTotal / newPageSize);

				this.setState({
					kkms: kkmsWithLabels,
					total: newTotal,
					totalPages: newTotalPages,
					currentPage: newCurrentPage,
					pageSize: newPageSize,
					kkmLoading: false,
					pending: true,
					isSearchMode: false,
					allKkms: [],
				}, () => {
					if (!this.state.currentExpiredDates || Object.keys(this.state.currentExpiredDates).length === 0) {
						this.fetchExpiredDates(choosenCompanyId);
					} else {
						const updatedKkms = this.getKkmsWithDates(this.state.kkms);
						this.setState({ kkms: updatedKkms, pending: false });
					}
				});
			}

		} catch (error) {
			console.error(error);
			this.setState({ kkmLoading: false });
		}
	}

	getCurrentPageKkms = () => {
		const { allKkms, currentPage, pageSize } = this.state;
		const startIndex = (currentPage - 1) * pageSize;
		const endIndex = startIndex + pageSize;
		return allKkms.slice(startIndex, endIndex);
	}

	onSearchChange = (newQuery) => {
		this.setState({ searchQuery: newQuery });
	}

	onSearchSubmit = () => {
		this.setState({ currentPage: 1 }, () => {
			this.fetchKkms(this.state.currentPage);
		});
	}

	onStatusChange = (statusId) => {
		this.setState({ status: statusId }, () => {
			this.fetchKkms(this.state.currentPage);
		});
	}

	setPage = (page) => {
		if (this.state.isSearchMode) {
			this.setState({ currentPage: page }, () => {
				const updatedKkms = this.getKkmsWithDates(this.getCurrentPageKkms());
				this.setState({ kkms: updatedKkms });
			})
		} else {
			this.setState({ currentPage: page }, () => {
				this.fetchKkms(page);
			});
		}
	};

	fetchExpiredDates = async (companyId) => {
		try {
			const response = await axios.get(`${PULT_URL}/fiscal/main/${companyId}/kkms/expired-list`, { headers });
			const expiredData = response.data;

			this.setState({
				currentExpiredDates: expiredData,
				pending: false
			}, () => {
				const updatedKkms = this.getKkmsWithDates(this.state.kkms);
				this.setState({ kkms: updatedKkms });
			});
		} catch (error) {
			console.error(error);
			this.setState({pending: false});
		}
	}

	selectCompany = async (e) => {
		const idx = Number(e.currentTarget.dataset.idx);
		const choosenCompanyId = Number(this.state.companies[idx].Id);

		localStorage.setItem('COMPANY', JSON.stringify(this.state.companies[idx]));

		this.setState({
			choosenCompanyId,
			kkmLoading: true,
			currentPage: 1,
			kkms: [],
			searchQuery: '',
			isSearchMode: false,
			allKkms: [],
			currentExpiredDates: null,
		}, () => {
			this.fetchKkms(1).then(() => {
				if (this.props.onSearchChange) {
					this.props.onSearchChange('');
				}
			});
		});
	};

	copyZnm = (e, znm) => {
		e.stopPropagation();
		const el = document.createElement('textarea');
		el.value = znm;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
		this.setState({
			copied: true,
			znmForCopy: znm
		}, () => {
			setTimeout(() => {
				this.setState({
					copied: false,
					znmForCopy: 0
				})
			}, 3000)
		})
	}

	openKkm = (e) => {
		const idKkm = Number(e.currentTarget.id);
		const idx = e.currentTarget.dataset['idx'];
		localStorage.setItem(`KKM`, JSON.stringify(this.state.kkms[idx]));
		localStorage.setItem(`CURRENT_KKM_ID`, JSON.stringify(idKkm));
		this.props.history.push(`/www/kkms/${idKkm}/shift_new`, { test: 'state' });
	}

	getKkmsWithLabels = (kkms) => {
		const user = getUser(this.props.history);
		return kkms.map(kkm => {
			if (kkm.IdStatusKkm === 1) return { ...kkm, LabelText: 'Касса в процессе регистрации', LabelType: user.Lock ? 'secondary' : 'danger' };
			if (kkm.IdStatusKkm === 3) {
				if (kkm.IsActive === false) return { ...kkm, LabelText: 'Касса заблокирована в ОФД и в K24B', LabelType: user.Lock ? 'secondary' : 'danger' };
				return { ...kkm, LabelText: 'Касса заблокирована в ОФД', LabelType: user.Lock ? 'secondary' : 'danger' };
			}
			if (kkm.IsActive === false) return { ...kkm, LabelText: 'Касса заблокирована в K24B', LabelType: user.Lock ? 'secondary' : 'danger' };
			if (kkm.Lock === false) return { ...kkm, LabelText: 'Касса доступна', LabelType: user.Lock ? 'available' : 'primary' };
			if (kkm.IdShift !== user.IdShift) return { ...kkm, LabelText: 'Смена открыта другим кассиром', LabelType: user.Lock ? 'secondary' : 'warning'};
			return { ...kkm, LabelText: user.Lock ? 'Ваша смена' : 'Касса доступна', LabelType: user.Lock ? 'success' : 'secondary' };
		})
	}

	getKkmsWithDates = (kkms) => {
		const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;
		const { currentExpiredDates } = this.state;

		kkms.forEach(kkm => {
			const date = currentExpiredDates[kkm.Id];
			if (date) {
				const expirationDate = new Date(date);
				const now = new Date();
				const diffDays = Math.round((expirationDate - now) / MILLISECONDS_IN_A_DAY);

				kkm.ExpirationDate = expirationDate;
				kkm.ExpirationDiffDays = diffDays;
			} else {
				kkm.ExpirationDate = null;
				kkm.ExpirationDiffDays = null;
			}
		});

		return kkms;
	}

	loadMoreKkms = async () => {
    const { choosenCompanyId, top, lastID, kkms } = this.state;

    if (!this.state.hasMore || this.state.isLoading) return;

    this.setState({ isLoading: true });

    try {
      const response = await axios.post(`${BASE_URL}/kkms`, {
        companies: [choosenCompanyId],
        top: top,
        lastID: lastID
      }, { headers, signal: this.controller.signal });

      const newKkms = response.data.Data.kkms || [];
      const newLastID = newKkms.length > 0 ? newKkms[newKkms.length - 1].Id : lastID;
      const hasMore = newKkms.length === top;
			const newKkmsWithLabel = this.getKkmsWithLabels(newKkms);

      this.setState(prevState => ({
        kkms: [...prevState.kkms, ...newKkmsWithLabel],
				lastID: newLastID,
				hasMore: hasMore,
				isLoading: false,
      }));
    } catch (error) {
      console.error(error);
      this.setState({ isLoading: false });
    }
  };

	componentDidMount() {
		let company, companies, usersCompanyId, choosenCompanyId, kkms, shift;

		Token = localStorage.getItem('tokken');
		headers = {
			'Authorization': `Bearer ${Token}`,
			'Content-Type': 'application/json',
		}

		const user = getUser(this.props.history);
		const query = async () => {
			try {
				const companiesResponse = await axios.get(`${BASE_URL}/companies`, { headers, signal: this.controller.signal });
				companies = companiesResponse.data.Data.Companies;

				if (companies === null || companies < 1) {
					throw new Error(JSON.stringify({
						body: { response: { data: { Message: 'У вас нет ни одного зарегистрированного юр. лица. Обратитесь в тех поддержку' }}}
					}))
				}
				if (user.Lock === true) {
					const shiftResponse = await axios.get(`${BASE_URL}/shifts/${user.IdShift}`, { headers, signal: this.controller.signal });

					shift = shiftResponse.data.Data;
					usersCompanyId = shift.IdCompany;
					choosenCompanyId = shift.IdCompany;

					const kkmResponse = await axios.get(`${BASE_URL}/kkms/${shift.IdKkm}`, { headers, signal: this.controller.signal });

					const kkm = kkmResponse.data.Data.Kkm;
					const idKkm = kkmResponse.data.Data.Kkm.Id;

					localStorage.setItem(`CURRENT_KKM_ID`, JSON.stringify(idKkm));
					localStorage.setItem(`KKM`, JSON.stringify(kkm));

					if (localStorage.getItem(`Uid_${idKkm}`) === null) {
						const uidResponse = await axios.get(`${BASE_URL}/uid/${idKkm}`, { headers, signal: this.controller.signal });
						const uid = uidResponse.data.Data.Uid;
						localStorage.setItem(`Uid_${idKkm}`, uid);
					}

					localStorage.setItem('SHIFT', JSON.stringify(shift));
					localStorage.setItem('OPEN_SHIFT_KKM_ID', JSON.stringify(shift.IdKkm));
					localStorage.setItem('SHIFT_INDEX', JSON.stringify(shift.ShiftIndex));

					if (localStorage.getItem('OPEN_SHIFT_BALANCES') === null) {
						const balancesResponse = await axios.get(`${BASE_URL}/kkms/${shift.IdKkm}/balances`, { headers, signal: this.controller.signal });
						const balances = await balancesResponse.data.Data.Balances;
						localStorage.setItem(`OPEN_SHIFT_BALANCES`, JSON.stringify(balances));

						this.setState({
							...this.state,
							balances: balances,
						})
					}

					if (localStorage.getItem('OPEN_SHIFT_RECEIPT_COUNT') === null) {
						const chartResponse = await axios.get(`${BASE_URL}/kkms/${shift.IdKkm}/chartsdata`, { headers, signal: this.controller.signal });
						const dashboardData = chartResponse.data.Data
						const totalReceiptCount = dashboardData.Sells.TotalReceipts + dashboardData.Refunds.TotalReceipts
						localStorage.setItem(`OPEN_SHIFT_RECEIPT_COUNT`, totalReceiptCount);
					}
				} else {
					choosenCompanyId = companies[0].Id
				}

				company = companies.length > 0 ? companies.filter(company => company.Id === choosenCompanyId)[0] : companies[0];
				localStorage.setItem('COMPANY', JSON.stringify(company));

				this.setState({
					companies,
					usersCompanyId,
					choosenCompanyId,
					companyName: companies[0].ShortName,
					user,
					total: this.state.total,
					mounted: true
				}, () => this.fetchKkms(this.state.currentPage).then(() => {}));

			} catch (error) {
				const data = getError(error, this.props.history)
				this.setState({
					errorData: data,
					error: true,
					mounted: true
				})
			}
		}

		query()

		if(isElectron() || localStorage.getItem('KKM_GARANT') || localStorage.getItem('KKM_LOMBARD') !== null) {
			const idKkm = JSON.parse(localStorage.getItem("CURRENT_KKM_ID"));
			const idx = 0;
			localStorage.setItem(`KKM`, JSON.stringify(this.state.kkms[idx]));
			this.props.history.push(`/www/kkms/${idKkm}`, { test: 'state' });
		}
	}

	componentWillUnmount() {
		this.controller.abort()
	}

	render() {
		return (
			this.state.mounted ?
				<React.Fragment>
					<CompaniesList
            {...this.state}
            copy={this.copyZnm}
            selectCompany={this.selectCompany}
            openKkm={this.openKkm}
            copyZnm={this.copyZnm}
            getKkmsWithLabels={this.getKkmsWithLabels}
						setPage={this.setPage}
						onSearchChange={this.onSearchChange}
          	onSearchSubmit={this.onSearchSubmit}
						onStatusChange={this.onStatusChange}
            { ...this.props }
          />
				</React.Fragment>
				: <PreloaderFullScreen />
		);
	}
}

export default Companies;
