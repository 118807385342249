import React from 'react'
import style from '../../../styles/pages/shift/tooltip.module.scss'
import { useTranslation } from "react-i18next";

const CustomizedTooltip = ({ payload }) => {
  const { t } = useTranslation()

  return (
    <div className={style.tooltip}>
      {
        payload.map(p => (
          p.name === "SellTotal" ? (
            <div className={style.operation} key={p.dataKey}>
              <div className={style.title}>
                <p>{ t('kassa.sales') }</p>
                {/* <div className={style.amount}>1 чек</div> */}
              </div>
              <div className={style.data}>
                <div className={style.block}>
                  <p className={style.blockTitle}>{t('kassa.non_cash')}</p>
                  <p className={style.sum}>{p.payload.SellCash} ₸</p>
                </div>
                <div className={style.block}>
                  <p className={style.blockTitle}>{t('kassa.top_sales_of_shift')}</p>
                  <p className={style.sum}>{p.payload.SellNonCash} ₸</p>
                </div>
                <div className={style.block}>
                  <p className={style.blockTitle}>{t('kassa.transfer')}</p>
                  <p className={style.sum}>{p.payload.SellMobile} ₸</p>
                </div>
                <div className={style.block}>
                  <p className={style.blockTitle}>{t('kassa.total')}</p>
                  <p className={style.sum}>{p.payload.SellTotal} ₸</p>
                </div>
              </div>
            </div>
          ) : (
            <div className={style.operation} key={p.dataKey}>
              <div className={style.title}>
                <p>{t('kassa.refunds')}</p>
                {/* <div className={style.amount}>1 чек</div> */}
              </div>
              <div className={style.data}>
                <div className={style.block}>
                  <p className={style.blockTitle}>{t('kassa.cash')}</p>
                  <p className={style.sum}>{p.payload.RefundCash} ₸</p>
                </div>
                <div className={style.block}>
                  <p className={style.blockTitle}>{t('kassa.non_cash')}</p>
                  <p className={style.sum}>{p.payload.RefundNonCash} ₸</p>
                </div>
                <div className={style.block}>
                  <p className={style.blockTitle}>{t('kassa.transfer')}</p>
                  <p className={style.sum}>{p.payload.RefundMobile} ₸</p>
                </div>
                <div className={style.block}>
                  <p className={style.blockTitle}>{t('kassa.total')}</p>
                  <p className={style.sum}>{p.payload.RefundTotal} ₸</p>
                </div>
              </div>
            </div>
          )
        ))
      }
    </div>
  )
}

export default CustomizedTooltip
